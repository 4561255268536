import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Eccc',
    url: '/eccc',
  },
]

const Eccc = () => {
  return (
    <Layout activeLink="/case-studies" title="R&D Eccc" description="R&D Eccc">
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Eccc"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Eccc" />
          <CaseStudyContent
            heading=""
            paragraph='"I have had the pleasure of knowing Kevin at RDA for many years now and I continuously hear of the success stories of claims the firm have been securing for their clients. What I have discovered is the different approach RDA take compared to most other research and development firms. They go into much more detail with their site visits and interviews, which in turn increases the quality of the claim they are able to achieve for their clients. I believe there is nothing stronger than personal recommendations and I’m always happy to recommend Kevin and his team."'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <h6>Darrell Fox, ECCC</h6>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default Eccc
